<template>
  <v-container fluid class="faq">
    <h3>{{ $t('helpCenter.faq.faqHeadline') }}</h3>
    <p class="faq__info" v-html="$t('helpCenter.faq.infoHtml')"></p>
    <div class="faq__links">
      <app-link-btn
        v-for="link in faqLinks"
        :key="link.title"
        :href="link.href"
        :title="link.title"
        append-icon="icon-open-in-new"
        class="faq__link"
        target="_blank"
      />
    </div>
  </v-container>
</template>

<script>
import FAQLinks from '@/modules/User/statics/faqLinks';

export default {
  name: 'TabFAQ',

  computed: {
    faqLinks() {
      return this.filterItemsWithFulfilledCondition([
        {
          condition: this.product.faq.includes(FAQLinks.DEBTORS),
          title: this.$t('helpCenter.faq.links.debtors'),
          href: 'https://www.fintecrity.de/faq-portal/'
        },
        /* TODO: update href when available
        {
          title: this.$t('helpCenter.faq.links.factoring'),
          href: '',
          condition: this.product.faq.includes(FAQLinks.FACTORING)
        },
        */
        {
          title: this.$t('helpCenter.faq.links.encashment'),
          href: 'https://www.eos-direct.de/faq-portal/',
          condition: this.product.faq.includes(FAQLinks.ENCASHMENT)
        }
      ]);
    }
  }
};
</script>

<style scoped lang="scss">
.faq {
  max-width: max(50%, 820px);
  margin-left: 0;
  padding-top: 12px !important;

  #app.mobile & {
    max-width: revert;
    padding-top: 24px !important;
  }

  &__info {
    margin-bottom: 32px !important;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__link {
    ::v-deep svg {
      height: 0.875rem;
    }
  }
}
</style>
