<template>
  <v-switch v-model="showTutorials" hide-details class="tutorial-setting__switch">
    <template #label>
      <icon-tutorials class="mr-2" width="24px" />
      {{ $t('settings.defaults.tutorialProposals') }}
    </template>
  </v-switch>
</template>

<script>
import { TUTORIAL_STORAGE } from '@/statics/storageKeys';

export default {
  name: 'TutorialSetting',

  computed: {
    showTutorials: {
      get() {
        return JSON.parse(localStorage.getItem(TUTORIAL_STORAGE)) !== false;
      },
      set(value) {
        this.$eventHub.$emit('update-tutorial-settings', !!value);
      }
    }
  }
};
</script>

<style scoped>
.tutorial-setting__switch {
  margin: 0;
  min-width: 300px;
}

>>> .v-input__slot {
  flex-direction: row-reverse;
}

>>> .v-input__slot > * {
  flex: 0 0 50%;
}

>>> .v-input__slot label {
  font-size: 14px;
  color: var(--c-secondary);
}

>>> .v-input--selection-controls__input {
  margin-right: -4px;
}
</style>
