<template>
  <app-tab-view-container :tabs="tabs" />
</template>

<script>
import AppTabViewContainer from '@/shared/components/AppTabViewContainer.vue';
import TabFAQ from '@/modules/User/components/HelpCenter/TabFAQ.vue';
import TabTutorials from '@/modules/User/components/HelpCenter/TabTutorials.vue';

export default {
  name: 'Tutorials',

  components: { AppTabViewContainer },

  computed: {
    tabs() {
      return [
        {
          id: 1,
          name: this.$t('helpCenter.faq.faq'),
          component: TabFAQ,
          condition: this.permissions.helpCenter.tabFAQ
        },
        {
          id: 2,
          name: this.$t('helpCenter.tutorials.videoTutorials'),
          component: TabTutorials,
          condition: this.permissions.helpCenter.tabTutorials
        }
      ];
    }
  }
};
</script>
