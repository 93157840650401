<template>
  <v-container fluid :class="{ 'pt-3': !isMobile, 'pt-5': isMobile }">
    <h3>{{ $t('helpCenter.tutorials.videoTutorials') }}</h3>

    <v-row no-gutters class="page__row flex-auto">
      <v-col cols="auto">
        <tutorials-setting />
      </v-col>
    </v-row>

    <v-row dense class="page__row flex-auto my-4" align="center">
      <v-col cols="12">
        <h4 class="text-h5 mb-6">{{ $t('helpCenter.tutorials.learnMore') }}</h4>
      </v-col>

      <v-col v-for="tag in tags" :key="tag.value" cols="auto">
        <v-chip
          outlined
          :color="selectedTags.includes(tag.value) ? 'primary' : 'secondary'"
          @click="selectTag(tag.value)"
        >
          {{ tag.label }}
        </v-chip>
      </v-col>
      <v-col cols="auto">
        <app-text-btn
          v-if="hasActiveFilters"
          class="filter-btn caption"
          title="resetFilter"
          @click="selectedTags = undefined"
        />
      </v-col>
    </v-row>

    <v-row dense class="page__row flex-auto">
      <v-col>
        <v-expansion-panels
          v-model="expandedTags"
          :disabled="!isMobile"
          :readonly="!isMobile"
          accordion
          flat
          multiple
        >
          <v-expansion-panel v-for="tag in tags" :key="tag.value">
            <template v-if="getTutorialsByTag(tag.value).length > 0">
              <v-expansion-panel-header class="text-h4 font-weight-light">
                {{ tag.label }}
              </v-expansion-panel-header>
              <v-divider class="page__row-divider" />

              <v-expansion-panel-content class="py-1">
                <div v-for="(tutorial, index) in getTutorialsByTag(tag.value)" :key="index">
                  <v-row align="stretch">
                    <v-col
                      :cols="isMobile ? 12 : 'auto'"
                      style="position: relative"
                      class="d-flex flex-column"
                    >
                      <div
                        v-ripple
                        class="tutorial__thumbnail"
                        @click="openVideoDialog(tutorial.videoId)"
                      >
                        <v-img
                          :src="`https://img.youtube.com/vi/${tutorial.videoId}/0.jpg`"
                          :max-width="isMobile ? 'unset' : '180'"
                          height="100%"
                        >
                          <div class="tutorial__thumbnail-icon-wrapper">
                            <v-icon size="80">mdi-youtube</v-icon>
                          </div>
                        </v-img>
                      </div>
                    </v-col>
                    <v-col>
                      <div class="grey-background pa-4">
                        <h4 class="text-h5">{{ tutorial.title }}</h4>
                        <p class="subtitle-1 my-2">{{ tutorial.info }}</p>
                        <app-link-btn
                          class="tutorial__video-btn"
                          color="text"
                          title="helpCenter.tutorials.watchVideo"
                          variant="muted"
                          @click="openVideoDialog(tutorial.videoId)"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="tutorialsWithSelectedTags.length === 0" class="mt-8">
          {{ $t('noMatchingResults') }}
        </div>
      </v-col>
    </v-row>

    <v-dialog content-class="video-dialog" :value="!!showVideo" @input="showVideo = undefined">
      <app-icon-btn
        class="video-dialog__close-btn"
        fab
        icon="mdi-close"
        x-small
        @click="showVideo = undefined"
      >
        <v-icon>mdi-close</v-icon>
      </app-icon-btn>
      <div class="video-dialog__video-container">
        <v-responsive :aspect-ratio="16 / 9">
          <iframe
            v-if="showVideo"
            class="video-dialog__video"
            :src="`https://www.youtube-nocookie.com/embed/${showVideo}?autoplay=1`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import TutorialsMixin from '@/mixins/TutorialsMixin';
import TutorialsSetting from '@/modules/User/components/TutorialsSetting.vue';
import { TUTORIAL_TAGS } from '@/modules/User/statics/tutorialTag';

export default {
  name: 'TabTutorials',

  mixins: [TutorialsMixin],

  components: { TutorialsSetting },

  data: () => ({
    mobileExpandedTags: undefined
  }),

  computed: {
    tags() {
      return TUTORIAL_TAGS.map((tag) => ({
        value: tag,
        label: this.$t(`helpCenter.tutorials.tags.${tag}`)
      }));
    },
    tutorialsWithSelectedTags() {
      return this.tutorials.filter((tutorial) =>
        this.selectedTags.length > 0
          ? tutorial.tags.some((tag) => this.selectedTags.includes(tag))
          : true
      );
    },
    selectedTags: {
      get() {
        return this.$route.query[this.queryParam.TAG] ?? [];
      },
      set(value) {
        this.setQueryParam(this.queryParam.TAG, value, true);
      }
    },
    expandedTags: {
      get() {
        return this.isMobile ? this.mobileExpandedTags : [...Array(this.tags.length).keys()];
      },
      set(value) {
        this.mobileExpandedTags = value;
      }
    },
    showVideo: {
      get() {
        return this.getQueryParam('showVideo');
      },
      set(value) {
        this.setQueryParam('showVideo', value, true);
      }
    },
    hasActiveFilters() {
      return this.selectedTags.length > 0;
    }
  },

  watch: {
    selectedTags: {
      handler(value) {
        if (!this.isMobile || value.length === 0) {
          return;
        }
        this.expandTagsFromSelectedTags();
      },
      immediate: true
    }
  },

  methods: {
    selectTag(selectedTag) {
      this.selectedTags = this.selectedTags.includes(selectedTag)
        ? this.selectedTags.filter((tag) => tag !== selectedTag)
        : [...this.selectedTags, selectedTag];
    },
    getTutorialsByTag(tag) {
      return this.tutorialsWithSelectedTags.filter((tutorial) => tutorial.tags.includes(tag));
    },
    expandTagsFromSelectedTags() {
      this.expandedTags = this.tags.reduce((indexListOfSelectedTags, tag, index) => {
        if (this.selectedTags.includes(tag.value)) {
          indexListOfSelectedTags.push(index);
        }
        return indexListOfSelectedTags;
      }, []);
    },
    openVideoDialog(videoId) {
      this.showVideo = videoId;
    }
  }
};
</script>

<style scoped lang="scss">
$video-dialog__close-btn--margin-top: 50px;

#app.v-application.mobile {
  .page__container {
    padding: 16px 16px 48px 16px;
  }
  .page__row {
    max-width: unset;
  }
  .page__row-divider {
    margin-top: 8px;
    margin-bottom: 14px;
    width: 100%;
  }

  .video-dialog__close-btn {
    right: -8px;
  }
}

.page__container {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px 32px;
}

.page__row {
  max-width: max(50%, 820px);

  .v-expansion-panel-header {
    padding: 0;
    min-height: unset;
    margin-top: 24px;
  }

  .theme--light.v-expansion-panels .v-expansion-panel--disabled {
    color: unset;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

.page__row-divider {
  margin-top: 16px;
  margin-bottom: 28px;
  width: 80%;
  color: #979797;
}

::v-deep .filter-btn.v-btn {
  text-decoration: underline;
  min-height: unset;
  padding: 4px;
}

.tutorial__thumbnail {
  cursor: pointer;
  flex: 1;
}

.tutorial__thumbnail-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: var(--c-secondary);
  z-index: 1;
}

.tutorial__thumbnail i {
  color: var(--c-secondary);
}

.tutorial__thumbnail:hover i {
  color: red;
}

::v-deep .video-dialog {
  width: min(100%, 1200px);
  position: relative;
  overflow: visible;
  margin: $video-dialog__close-btn--margin-top 16px 8px 16px;

  .v-responsive {
    max-height: calc(100vh - #{$video-dialog__close-btn--margin-top} - 16px);
  }
}

.video-dialog__close-btn {
  position: absolute;
  top: calc(-#{$video-dialog__close-btn--margin-top} + 4px);
  right: -16px;
  background-color: var(--c-white) !important;
  color: var(--c-text) !important;
}

.video-dialog__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
