<template>
  <v-container fluid :class="{ 'pa-0': true, 'pt-8': !isMobile }">
    <v-sheet elevation="16" :class="{ 'd-flex flex-column pb-4': true, 'fill-height': !isSafari }">
      <v-row v-if="filterItemsWithFulfilledCondition(tabs).length > 1" no-gutters class="flex-auto">
        <v-col cols="12" md="8" lg="6" :class="{ 'py-4 pl-6': !isMobile }">
          <app-tabs v-model="activeTab" :tabs="filterItemsWithFulfilledCondition(tabs)" />
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-grow-1">
        <v-col cols="12" class="d-flex justify-center align-stretch">
          <app-error-alert v-if="error" class="align-self-center" />
          <app-spinner v-else-if="loading || !activeTabConfig" class="align-self-center" />
          <component
            :is="activeTabConfig.component"
            v-else
            v-bind="tabProps"
            :class="{ 'px-8 py-0': !isMobile }"
          />
        </v-col>
      </v-row>

      <slot name="append"></slot>
    </v-sheet>
  </v-container>
</template>

<script>
import RoutesMixin from '@/mixins/RoutesMixin';

export default {
  name: 'AppTabViewContainer',

  mixins: [RoutesMixin],

  props: {
    error: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    tabProps: {
      type: Object,
      default: () => ({})
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    activeTab: 0
  }),

  computed: {
    activeTabConfig() {
      return this.filterItemsWithFulfilledCondition(this.tabs)[this.activeTab];
    }
  },

  async created() {
    if (!this.activeTabConfig) {
      await this.$router.replace({ name: this.defaultRoute.route });
    }
  }
};
</script>

<style scoped>
>>> h3,
>>> h3 + .align-inline {
  font-weight: 600 !important;
  margin: 12px 0 24px 0;
}
>>> .v-tab {
  font-size: 16px;
  justify-content: center;
}

>>> .tab__col.col-6 {
  padding-right: 5rem;
}
</style>
